import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  transform(dateUtc: string, format: string, utc?: number) {

    if (dateUtc && utc != null){
      return moment(dateUtc).utcOffset(utc).format(format);
    }
    else if (dateUtc && utc == null) {
      return moment(dateUtc).format(format);
    }
    else {
      return ""
    }
  }
}
