import{ MomentPipe} from "./moment.pipe";
import {NgModule} from "@angular/core";
import { ConvertStatusEnFrPipe } from './convert-status-en-fr.pipe';



@NgModule({
  imports: [],
    exports: [MomentPipe, ConvertStatusEnFrPipe],
  declarations: [MomentPipe, ConvertStatusEnFrPipe]
})
export class PipeModule {

}
