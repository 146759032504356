import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {SdhttpInterceptorService} from './gateways/services/SDHttpInterceptorServices/sdhttp-interceptor.service';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { GetSitePipe } from './pipes/get-site.pipe';
// import { MomentPipe } from './pipes/moment.pipe';

import {PipeModule} from "./pipes/pipe.module";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";


@NgModule({
    declarations: [AppComponent, GetSitePipe],
    entryComponents: [],
    imports: [
        BrowserModule, IonicModule.forRoot(), AppRoutingModule,
        HttpClientModule,
      PipeModule
    ],
    providers: [
        // {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
	    {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: SdhttpInterceptorService, multi: true},
        HttpClientModule,
        BarcodeScanner

    ],
    bootstrap: [AppComponent],
  exports: [
    GetSitePipe
  ]
})
export class AppModule {}
