import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'select-site',
    loadChildren: () => import('./pages/select-site/select-site.module').then( m => m.SelectSitePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'colisage',
    loadChildren: () => import('./pages/colisage/colisage.module').then( m => m.ColisagePageModule)
  },
  {
    path: 'suivi',
    loadChildren: () => import('./pages/suivi/suivi.module').then( m => m.SuiviPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'passage-du-jour',
    loadChildren: () => import('./pages/passage-du-jour/passage-du-jour.module').then( m => m.PassageDuJourPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'modal-open-container',
    loadChildren: () => import('./pages/modal-open-container/modal-open-container.module').then( m => m.ModalOpenContainerPageModule)
  },
  {
    path: 'modal-user-contact',
    loadChildren: () => import('./pages/modal-user-contact/modal-user-contact.module').then( m => m.ModalUserContactPageModule)
  },
  {
    path: 'modal-show-deliveries-pppage',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./pages/modal-show-deliveries-pppage/modal-show-deliveries-pppage.module').then( m => m.ModalShowDeliveriesPPPagePageModule)
  },
  {
    path: 'modal-show-result',
    loadChildren: () => import('./pages/modal-show-result/modal-show-result.module').then( m => m.ModalShowResultPageModule)
  },
  {
    path: 'suivi-wp',
    loadChildren: () => import('./pages/suivi-wp/suivi-wp.module').then( m => m.SuiviWPPageModule)
  },
  {
    path: 'suivi-ip',
    loadChildren: () => import('./pages/suivi-ip/suivi-ip.module').then( m => m.SuiviIPPageModule)
  },
  {
    path: 'suivi-deliver',
    loadChildren: () => import('./pages/suivi-deliver/suivi-deliver.module').then( m => m.SuiviDeliverPageModule)
  },  {
    path: 'in-container',
    loadChildren: () => import('./pages/in-container/in-container.module').then( m => m.InContainerPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
