import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertStatusEnFr'
})
export class ConvertStatusEnFrPipe implements PipeTransform {

  transform(statusEn: string): string {
    switch (statusEn) {
      case 'WAITING_PICKUP' : return 'EN ATTENTE DE RAMASSAGE'.toLowerCase();
      case 'IN_PROGRESS' : return 'EN COURS DE LIVRAISON'.toLowerCase();
      case 'WAITING_RECEPTION' : return 'EN ATTENTE DE RÉCÉPTION'.toLowerCase();
      case 'DELIVER' : return 'RECEPTIONNÉ'.toLowerCase();
      case 'DELIVER_ABNORMAL' : return 'CLOTURÉ DE FACON ANORMAL'.toLowerCase();
      case 'WAITING_CONNEXION' : return 'EN TRANSIT SUR UN SITE'.toLowerCase();
      case 'IN_TRANSIT' : return 'EN ATTENTE DE LIAISON'.toLowerCase();
      default : return '';
    }
  }

}
